<template>
  <CDataTable
    :table-filter="false"
    
    
    hover
    
    :border="border"
    
    
    :items="items"
    :fields="fields"
    :items-per-page="small ? 10 : 5"
    :dark="dark"
    pagination
    dense
    responsive
  >
  <template #status="{item}">
          <td>
            <CBadge :color="showStatus(item.status)">{{item.status}}</CBadge>
          </td>
        </template>
        <template #show_details="{item, index}">
        <td class="py-2">
          <CButton
            :color="buttonColor"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{Boolean(item._toggled) ? 'less' : 'more'}}
          </CButton>
        </td>
      </template>
  </CDataTable>
</template>



<script>

export default {
  name: "InventoryGrid",
  components: {
   
  },
  props: {
    items: Array,
    buttonColor:{  // Custom Style based on user's theme 
        type: String,
        default: "info"
    },
    fields: {
      type: Array,
      default() {
        return [{key: "name", label:"Name"}, "branch", {key: "device", label:"Device ID"},{key: "radio", label:"Radio Type"}, {key:"show_details",label:"Details",responsivePriority:1}]; 
        //return ["device", "branch", {key: "truck", label:"Name"},"radio", {key: "status", label:"State"}, {key:"show_details",label:"Details"}];
      }
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  data(){
    return{
      details: [],
      collapseDuration: 0
    }
  },
  methods: {
    toggleDetails (item, index) {
      this.$emit("clickDetails",item, index);
    },
    showStatus(status,index){
      switch(status){
        case 'active':return 'success'
        case 'inactive': return 'secondary'
        case 'idle': return 'warning'
        default: return 'danger'
      }
    }
  },
};
</script>