var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { attrs: { "align-horizontal": "between" } },
        [
          _c("CCol", { staticClass: "ml-3" }, [
            _c(
              "h2",
              { staticClass: "pt-2 m-0", attrs: { id: "active-message-text" } },
              [_vm._v(_vm._s(_vm.activemsg) + "% Active")]
            ),
            _c(
              "p",
              { staticClass: "p-0 m-0", attrs: { id: "inactive-messge-text" } },
              [_vm._v(_vm._s(_vm.inactive) + "% Inactive")]
            )
          ]),
          _c(
            "CCol",
            { staticClass: "mb-2" },
            [
              _c("CChartDoughnut", {
                attrs: {
                  id: "donut",
                  datasets: _vm.defaultDatasets,
                  labels: ["Active", "Inactive"],
                  options: _vm.defaultOptions,
                  height: "10px"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }