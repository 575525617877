var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CDataTable", {
    attrs: {
      "table-filter": false,
      hover: "",
      border: _vm.border,
      items: _vm.items,
      fields: _vm.fields,
      "items-per-page": _vm.small ? 10 : 5,
      dark: _vm.dark,
      pagination: "",
      dense: "",
      responsive: ""
    },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "td",
              [
                _c(
                  "CBadge",
                  { attrs: { color: _vm.showStatus(item.status) } },
                  [_vm._v(_vm._s(item.status))]
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "show_details",
        fn: function(ref) {
          var item = ref.item
          var index = ref.index
          return [
            _c(
              "td",
              { staticClass: "py-2" },
              [
                _c(
                  "CButton",
                  {
                    attrs: {
                      color: _vm.buttonColor,
                      variant: "outline",
                      square: "",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleDetails(item, index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(Boolean(item._toggled) ? "less" : "more") +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }