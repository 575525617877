
<template>
  <!-- Creates a Widget that we can re-use for admin pages -->
  <div>
    <CRow>
      <CCol>
        <agile :options="myOptions" :key="show">
  
          <div class="slide">
            
            <CCard :color="backColor" class="pb-1 mx-1 admin-widget header-widget">
              <CCardHeader
                v-if="showHeader"
                text-color="white"
                class="pt-0 pb-0"
              >
              </CCardHeader>
              <CCardBody text-color="white" class="pb-1 pt-1">
                <strong style="font-size: 20px"> Fleet Activity</strong>
                <CChartFleetActivityDonut
                  :activemsg="activeDevices"
                  :inactive="inactiveDevices"
                  :themeColor="color"
                />
              </CCardBody>
            </CCard>
          </div>
          <div class="slide">
            <CCard :color="backColor" class="pb-1 mx-1 admin-widget header-widget">
              <CCardHeader
                v-if="showHeader"
                text-color="white"
                class="pt-0 pb-0"
              >
              </CCardHeader>
              <CCardBody text-color="white" class="pb-1 pt-1">
                <strong style="font-size: 20px"> Last 7 Days Activity</strong>
                <CChartLineSimple
                  ref="weeklyActivity"
                  pointed
                  class="mt-3 mx-3"
                  style="height: 70px"
                  :data-points="weeklyDataPoints"
                  background-color="rgba(255,255,255,0.25)"
                  :point-hover-background-color="color"
                  :options="weeklyActivityOptions"
                  label="Active"
                  :labels="weeklyLabels"
                />
              </CCardBody>
            </CCard>
          </div>
          <div class="slide">
            <ReportsWidget
            :back-color="backColor" 
            @click="console.log('clicked')"
            >

            </ReportsWidget>
          </div>
          

          <template slot="prevButton"
            ><CIcon name="cil-chevron-left"
          /></template>
          <template slot="nextButton"
            ><CIcon name="cil-chevron-right"
          /></template>
        </agile>
      </CCol>
    </CRow>
    
    
  </div>
</template>


<style scoped>
.admin-widget {
  min-height: 135px;
  max-height: 135px;
}
.footer-content {
  max-height: 100px;
}
</style>

<script>
import {
  CChartLineSimple,
  CChartBarSimple,
} from "../components/chartLib/index.js";
import CChartFleetActivityDonut from "../components/chartLib/CChartFleetActivityDonut";
import { VueAgile } from "vue-agile";
import { GridLayout, GridItem } from "vue-grid-layout";
import ThemeMixin from "../mixins/ThemeMixin"; 
import SingleDisplayWidget from "./SingleDisplayWidget.vue";
import ReportsWidget from "./ReportsWidget.vue";

const weekday = new Array(7);
weekday[0] = "Sun";
weekday[1] = "Mon";
weekday[2] = "Tue";
weekday[3] = "Wed";
weekday[4] = "Thu";
weekday[5] = "Fri";
weekday[6] = "Sat";

export default {
  name: "AdminWidget",
  components: {
    CChartLineSimple,
    agile: VueAgile,
    GridLayout,
    GridItem,
    ReportsWidget ,
    //CChartBarSimple,
    //ActivityDonutChart,
    CChartFleetActivityDonut,
    SingleDisplayWidget
},
  props: {
    dataSets: {
      type: Object,
      default: {
        fleetActivity: { active: 0, inactive: 100 },
        weeklyActivity: [
          { active: 10, inactive: 100, date: 0 },
          { active: 20, inactive: 100, date: 0 },
          { active: 30, inactive: 100, date: 0 },
          { active: 40, inactive: 100, date: 0 },
          { active: 50, inactive: 100, date: 0 },
          { active: 60, inactive: 100, date: 0 },
          { active: 70, inactive: 100, date: 0 },
        ],
      },
    },
  },
  mixins:[ThemeMixin], 
  data() {
    return {
      myOptions: {
        navButtons: false,
        slidesToShow: 2,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        /** Default Breakpoints are 175, 225, 400, 600 and 900  */
        responsive: [
          {
            breakpoint: 175,
            settings: {
              navButtons: true,
              dots: true,
              slidesToShow: 1,
              autoplay: true,
            },
          },
          {
            breakpoint: 225,
            settings: {
              navButtons: true,
              dots: true,
              slidesToShow: 1,
              autoplay: true,
            },
          },
          {
            breakpoint: 400,
            settings: {
              navButtons: true,
              dots: true,
              slidesToShow: 1,
              autoplay: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              navButtons: true,
              dots: false,
              slidesToShow: 2,
              autoplay: true,
            },
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: false,
              dots: false,
              infinite: true,
              slidesToShow: 2,
              autoplay: false,
            },
          },
        ],
      },
      show: false,
      showHeader: false,

      color: "#efefef", // "#efefef",//"#b88a00", // The Primary color of the data set.
      //activemsg: 75,
      //inactive: 25,
      weeklyActivityOptions: {
        tooltips: {
          enabled: true,
          mode: "nearest",
          //custom: function(){}

          // callbacks:{} // Overrided Doesn't seem to work in this version !!!
        },
        elements: { line: { tension: 0.00001 } },
      },
      options: {
        //weeklyActivity:{ elements: { line: { tension: 0.00001 }},tooltips:{callbacks:{ label:(item)=>`${item.yLabel} %`}}}
      },
      labels: {
        weeklyActivity: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    };
  },
  computed: {
   
    activeDevices() {
      return this.dataSets.fleetActivity
        ? this.dataSets.fleetActivity.active
        : 0;
    },
    inactiveDevices() {
      return this.dataSets.fleetActivity
        ? this.dataSets.fleetActivity.inactive
        : 100;
    },
    weeklyDataPoints() {
      let temp = [];
      if(!this.dataSets.weeklyActivity) return temp;

      this.dataSets.weeklyActivity.sort((a,b)=>a.timestamp - b.timestamp); 
      for (var i = 0; i < this.dataSets.weeklyActivity.length; i++) {
       
        temp.push(this.dataSets.weeklyActivity[i].active);
      }
      return temp;
    },
    weeklyLabels() {
      let temp = [];
      if(!this.dataSets.weeklyActivity) return temp;
      for (var i = 0; i < this.dataSets.weeklyActivity.length; i++) {
        // TODO: Replace this method with Moment.format()
        var d = new Date(this.dataSets.weeklyActivity[i].date);
        var dayOfWeek = d.getDay();
        //alert(this.$moment(d).format("MM:DD:YY"));
        var txt = weekday[dayOfWeek] + " " + this.$moment(d).format("MMM Do");
        temp.push(txt);
      }
      this.labels.weeklyActivity = temp;
      return temp;
    },
  },
  methods: {
    getTheme() {
      return this.$store.state.theme.backColor
        ? this.$store.state.theme.backColor
        : "gradient-warning";
      //return this.$auth.user.theme.backColor?this.$auth.user.theme.backColor:"gradient-warning";
    },
  },
   activated(){
    this.show = true; 
  }, 
  deactivated(){
    this.show = false; 
  },
  mounted() {
    try {
      //console.log(this.$auth.user.theme.backColor);
      //themeColor = this.$auth.user.theme.backColor.toString();
      //let color = this.$auth.user.theme.highlight.toString();
      //this.theme = themeColor;
      //this.color = color;
    } catch (err) {
      console.log(err);
    }
    //this.theme = "#acacac"//this.$auth.user.theme.backColor; //"gradient-primary"
    /* KH: Was trying to hack the tooltips callbacks here. Doesn't work 
     if(this.$refs.weeklyActivity){
       console.log(this.$refs.weeklyActivity.options.tooltips);
      this.$refs.weeklyActivity.options.tooltips['callbacks']= {
        label: (tooltipItems, data) => {
            return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' GB';
        }
    }
    */
  },
};
</script>
