<template>
  <div>
    <!-- Creates a Widget Layout for our Main Admin Dash for our application -->
    <!--AdminWidget :dataSets="dataSets" :key="$store.selectBranch" />
    <!-- Puts a Chart into a Card with a Special Header -->
    
    <CRow class="no-gutters">
      <CCol>
        <TruckStatusControl
        ref="statuscontrol"
        
        :branchName="branchName"
        :branch-id="branchCode"
        @row-clicked="handleRowClick"
        >

        </TruckStatusControl>
      </CCol>



    
    
    </CRow>
  </div>
</template>

<style scoped>
.admin-card {
  min-height: 250px;
  /*background-color: var(--light);*/
  max-height: 350px;
}

.admin-card-header {
  /*background-color: var(--light);*/
  padding: 2px;
}
.map-frame {
  min-height: 400px;
  height: 400px;
}
</style>

<script>
import AdminWidget from "@/widgets/AdminWidget";


import InventoryGrid from "@/components/tables/InventoryGrid";
import RecentActivityList from "@/components/timeline/RecentActivityList";
import DashboardCard from "@/components/dashboard/DashboardCard";
import LiveTrackingWidget from "@/widgets/LiveTrackingWidget";

import ThemeMixin from "@/mixins/ThemeMixin"; // Make the view(s) Themeable
import TruckStatusControl from "./app/TruckStatusControl.vue";

/** Debugging * Testing Section  */

const UPDATE_PERIOD = 10000;

export default {
  name: "AdminView",
  components: {
    //MainChartExample,
    //WidgetsDropdown,
    //WidgetsBrand,
    AdminWidget,
    InventoryGrid,
    RecentActivityList,
    LiveTrackingWidget,
    DashboardCard,
    TruckStatusControl
},
  mixins: [ThemeMixin],
  data() {
    return {
      selected: "Month",
      mapSrc: "https://poc.aarcomm.io/dev/lonestar-fleet-map",
      timerHandle: undefined,
      backgroundSync: true, 
      //myRecentActivity: [],
      //inventory: [],
      status: [],
      dataSets: {
        fleetActivity: { inactive: 100, active: 0 },
        weeklyActivity: [10, 20, 40, 80, 160, 320],
      },
      //theme: "gradient-info",
      active: false,
    };
  },
  computed: {
    branchName(){
      return this.branchInfo? this.branchInfo.name: ""; 
    }, 
    statusList(){
      let temp = this.inventory.filter(item=>item.status != "deactivated" && item.status != "ready");
      temp = temp.map(item=>{
        let statusItem={
          ...item,
        }
        statusItem.status = "stopped"; 
        
        return statusItem;
      })
      return temp
    },
    fleetStatus() {
      return this.status
        ? this.status.filter(
            (item) => item.status != "deactivated" && item.status != "ready"
          )
        : [];
    },
    branchInfo: {
      get() {
        return this.$store.getters.selectedBranch;
      },
    },
    branchCode(){
      if(!this.branchInfo){
        return ""; 
      }
      return this.branchInfo.metadata.code;
    },
    inventory() {
      if (!this.$store.getters.inventory) return [];
      return (
        this.$store.getters.inventory.filter(
          (item) => (item.branch_id === this.$app.myBranchId && item.status==="active")
        ) || []
      ); //this.$app.myDevices; //this.$store.getters.inventory || [];
    },
    myRecentActivity() {
      let activity = this.$store.getters.activity;
      
      
      
      return activity; 
    },
  },
  methods: {
    handleRowClick(item, index) {
      //console.log(item, index);
      this.showFleet(item, index);
    },
    toggleDetails(item, index) {
      this.showFleet(item, index);
    },
    showFleet(item, index) {
      this.$router.push({
        path: "/dashboard/device",
        params: { device: item.device },
        query: { id: item.device },
      });
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },



    isExpired() {
      if (Date.now() - this.$store.state.dataStore.age > 5000) return true;
      return false;
    },
    async load(onLoadCallback) {
      try {
        /// Try Load the Dashboards in the background
        //await this.$app.loadDashboards(); // Dont need to reload the dashboards
      } catch (err) {
        console.log(err);
      }
    },
    async update(sender) {
      // TODO: update the Widgets
      try {
        //if (this.isExpired()) {
        {
          //this.inventory = await this.$app.loadInventory();
          //this.inventory = this.$store.getters.inventory;
          let branch_id = this.$app.myBranchId;

          let params = {
            branch_id: branch_id,
          };

          console.log(params);
          this.status = await this.$fleet.loadFleetStatus(params); 


          //this.status = await this.$app.getFleetStatus(params);

          console.log(branch_id,this.status);

          // Filter by Selected Branch
          this.status = this.status.filter(
            (item) => item.branch_id === branch_id
          );

          this.dataSets.fleetActivity = await this.$fleet.loadFleetActivity(params); 



          //this.dataSets.weeklyActivity = await this.$app.getWeeklyFleetActivityAsync({start:"now()-7d"});
          //console.log(this.dataSets.weeklyActivity);
          /*
         

         this.dataSets.fleetActivity = await this.$app.getFleetActivityAsync(); 
         
          this.getFleetActivityAsync().then((res) => {
            // TODO: This Call should be pushed down a Layer and Commited to Data Store
            if (res.status === 200) {
              // Should return an Array of Objects. In this case it is singular array
              this.dataSets.fleetActivity = res.data[0];
            }
          });
          */
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    if (this.backgroundSync) {
      this.timerHandle = setInterval(() => {
        this.update();
      }, UPDATE_PERIOD);
    }
  },
  destroyed() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
  },
  activated() {
    this.active = true;
    const statuscontrol = this.$refs.statuscontrol;
    if(statuscontrol){
      statuscontrol.update(); 
    }
    let branch_id = this.$app.myBranchId;
    if (this.$api.config.worldmap)
      this.mapSrc = `${this.$api.config.worldmap}?branch_id=${branch_id}`;
    else {
      this.mapSrc = "";
    }
  },
  deactivated() {
    this.active = false;
  },
  async mounted() {
    /** On Mounted, Load the  */
    //usersData: usersData.map((item, id) => { return {...item, id}}),
    try {
      await this.load();

      if (this.$store.getters.weeklyfleetactivity === null) {
        this.dataSets.weeklyActivity =
          await this.$app.getWeeklyFleetActivityAsync();
      } else {
        this.dataSets.weeklyActivity = this.$store.getters.weeklyfleetactivity;
      }
      //this.status = await this.$app.getFleetStatus();
      await this.update();
      /*
      if (this.isExpired()) {
        // Only get the Inventory if it's not already loaded
          await this.update(); 
      } 
      */
    } catch (err) {
      console.log(err);
    }
  },
  watch: {
    branchInfo(newBranch, oldBranch) {
      this.$nextTick(async () => {
        try {
          this.status = [];
          await this.load();

          if (this.$store.getters.weeklyfleetactivity === null) {
            let resp = await this.$app.getWeeklyFleetActivityAsync();
            if (resp) {
              this.dataSets.weeklyActivity = resp;
            }
          } else {
            this.dataSets.weeklyActivity =
              this.$store.getters.weeklyfleetactivity;
          }
          //this.status = await this.$app.getFleetStatus();
          await this.update();
        } catch (err) {
          console.log(err);
        }
      });
    },
  },
};
</script>
