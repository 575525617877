<template>
    <CCard :color="backColor" class="pb-1 mx-1 admin-widget header-widget">
              <CCardHeader
                v-if="showHeader"
                text-color="white"
                class="pt-0 pb-0"
              >
              </CCardHeader>
              <CCardBody text-color="white" class="pb-1 pt-1"
              
              >
                <span><strong style="font-size: 20px"> Reports </strong></span>
                <CChartLineSimple
                  ref="weeklyActivity"
                  pointed
                  class="mt-3 mx-3"
                  style="height: 70px"
                  :data-points="weeklyDataPoints"
                  background-color="rgba(255,255,255,0.25)"
                  :point-hover-background-color="color"
                  :options="weeklyActivityOptions"
                  label="Active"
                  :labels="weeklyLabels"
                />
              </CCardBody>
            </CCard>
</template>



<script>

const weekday = new Array(7);
weekday[0] = "Sun";
weekday[1] = "Mon";
weekday[2] = "Tue";
weekday[3] = "Wed";
weekday[4] = "Thu";
weekday[5] = "Fri";
weekday[6] = "Sat";


export default {
    name:"ReportsWidget", 
    components:{

    }, 
    props:{
        backColor: String, 
        showHeader: Boolean, 
        weeklyActivity:
        {type: Array, default: ()=>[
          { active: 10, inactive: 100, date: 0 },
          { active: 20, inactive: 100, date: 0 },
          { active: 30, inactive: 100, date: 0 },
          { active: 40, inactive: 100, date: 0 },
          { active: 50, inactive: 100, date: 0 },
          { active: 60, inactive: 100, date: 0 },
          { active: 70, inactive: 100, date: 0 },
        ],
        }
    }, 
    data(){
        return{
            weeklyActivityOptions: {
        tooltips: {
          enabled: true,
          mode: "nearest",
          //custom: function(){}

          // callbacks:{} // Overrided Doesn't seem to work in this version !!!
        },
        elements: { line: { tension: 0.00001 } },
      },
      options: {
        //weeklyActivity:{ elements: { line: { tension: 0.00001 }},tooltips:{callbacks:{ label:(item)=>`${item.yLabel} %`}}}
      },
      labels: {
        weeklyActivity: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
        }
    }, 
    methods:{
        
    }
}
</script>