<template>
<div>
  <CRow align-horizontal="between">
    <CCol class="ml-3">
      
      <h2 id="active-message-text" class="pt-2 m-0">{{ activemsg }}% Active</h2>
      <p id="inactive-messge-text" class="p-0 m-0">{{ inactive }}% Inactive</p>
      
    </CCol>
 
 <CCol class="mb-2">
  <CChartDoughnut id="donut"
    :datasets="defaultDatasets"
    :labels="['Active', 'Inactive']"
    :options="defaultOptions"
    height ="10px"
  />
 </CCol>
   </CRow>
  </div>
</template>

<style scoped>
#donut{
  margin-top:-10px;
}

#active-message-text{
  color: "light"
  /*font-weight: bold*/;
}
#inactive-messge-text{
  color: #5d5d5d !important;
}
</style>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'



export default {
  name: 'CChartFleetActivityDonut',
  components: { CChartDoughnut },
  props:{
    ... CChartDoughnut.props, 
    activemsg: Number,
    inactive: Number,
    themeColor: {
      type: String,
      default:  '#b88a00',
    }, 
    inactiveColor:{
      type: String, 
      default: "#5d5d5d",
    }
  },
  data(){
    return{
      //activemsg:75,
      //inactive:25,
      height:"30px"
    }
  },
  computed: {
    defaultOptions() {
      return {
        legend: {
            display: false
            },
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 3,
            height: "100%"   
            
      }
    },
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            this.themeColor, 
            this.inactiveColor,
          ],
          data: [this.activemsg, this.inactive]
        }
      ]
    }
  }
}
</script>
