var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "agile",
                { key: _vm.show, attrs: { options: _vm.myOptions } },
                [
                  _c(
                    "div",
                    { staticClass: "slide" },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "pb-1 mx-1 admin-widget header-widget",
                          attrs: { color: _vm.backColor }
                        },
                        [
                          _vm.showHeader
                            ? _c("CCardHeader", {
                                staticClass: "pt-0 pb-0",
                                attrs: { "text-color": "white" }
                              })
                            : _vm._e(),
                          _c(
                            "CCardBody",
                            {
                              staticClass: "pb-1 pt-1",
                              attrs: { "text-color": "white" }
                            },
                            [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "20px" } },
                                [_vm._v(" Fleet Activity")]
                              ),
                              _c("CChartFleetActivityDonut", {
                                attrs: {
                                  activemsg: _vm.activeDevices,
                                  inactive: _vm.inactiveDevices,
                                  themeColor: _vm.color
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "slide" },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "pb-1 mx-1 admin-widget header-widget",
                          attrs: { color: _vm.backColor }
                        },
                        [
                          _vm.showHeader
                            ? _c("CCardHeader", {
                                staticClass: "pt-0 pb-0",
                                attrs: { "text-color": "white" }
                              })
                            : _vm._e(),
                          _c(
                            "CCardBody",
                            {
                              staticClass: "pb-1 pt-1",
                              attrs: { "text-color": "white" }
                            },
                            [
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "20px" } },
                                [_vm._v(" Last 7 Days Activity")]
                              ),
                              _c("CChartLineSimple", {
                                ref: "weeklyActivity",
                                staticClass: "mt-3 mx-3",
                                staticStyle: { height: "70px" },
                                attrs: {
                                  pointed: "",
                                  "data-points": _vm.weeklyDataPoints,
                                  "background-color": "rgba(255,255,255,0.25)",
                                  "point-hover-background-color": _vm.color,
                                  options: _vm.weeklyActivityOptions,
                                  label: "Active",
                                  labels: _vm.weeklyLabels
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "slide" },
                    [
                      _c("ReportsWidget", {
                        attrs: { "back-color": _vm.backColor },
                        on: {
                          click: function($event) {
                            return _vm.console.log("clicked")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "prevButton" },
                    [_c("CIcon", { attrs: { name: "cil-chevron-left" } })],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "nextButton" },
                    [_c("CIcon", { attrs: { name: "cil-chevron-right" } })],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }