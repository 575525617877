var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { staticClass: "no-gutters" },
        [
          _c(
            "CCol",
            [
              _c("TruckStatusControl", {
                ref: "statuscontrol",
                attrs: {
                  branchName: _vm.branchName,
                  "branch-id": _vm.branchCode
                },
                on: { "row-clicked": _vm.handleRowClick }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }